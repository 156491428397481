import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import he from 'he'

import AdertiseForm from '../components/advertise-form'
import BasicContent from '../components/basic-content'
import SocialBar from '../components/social-bar'

const social = {
  title: 'Add me to the Dad list',
  className: 'full'
}


class AdvertisePage extends Component {
  render() {
    const contact = this.props.data.wordpressPage
    return (
      <>
        <Helmet>
          <body />
          <title>{ he.decode(this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title) }</title>
          <meta name="description" content={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc} />
        </Helmet>
        <AdertiseForm {...contact} />
        <SocialBar {...social} />
        <BasicContent {...contact} />
      </>
    )
  }
}

export default AdvertisePage

export const advertiseQuery = graphql`
  query advertiseQuery {
    wordpressPage(title: {eq: "Workplace Support"}) {
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        title
        form_title
        featured_image {
          url
          alt
        }
      }
    }
  }
`
