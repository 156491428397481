import React, {Component} from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class AdvertiseForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      enquiry: '',
      emailValidation: '',
      nameValidation: ''
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if ( this.state.name === '' || this.state.email === '' ) {
      if ( this.state.name === '' ) {
        this.setState({result: 'fail', nameValidation: 'Please enter your name.' })
      } else {
        this.setState({result: 'fail', nameValidation: '' })
      }
      if ( this.state.email === '' ) {
        this.setState({result: 'fail', emailValidation: 'Please enter an email address.' })
      } else {
        this.setState({result: 'fail', emailValidation: '' })
      }
    } else {
      fetch("/contact?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
        .then(() => this.setState({result: 'success', msg: 'Thanks for contacting us. One of the team will be in touch shortly.', emailValdiation:'', nameValdiation:''}))
        .catch(error => this.setState({result: 'fail', msg: error}));
    }
  }

  render() {

    let backgroundImage = this.props.acf.featured_image.url

    return(
      <section className="contact-form" style={{ backgroundImage: `url(${backgroundImage})`}}>
        <div className="contact-form__inner">
          <div className="contact-form__content">
            <p className="contact-form__title">{this.props.acf.title}</p>
          </div>
          <div className="contact-form__form">
            <h1>{this.props.acf.form_title}</h1>
            { this.state.result !== 'success' &&
              <form
                className="form"
                name="advertise"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="bot-field" />
                <div className="form__row">
                  <input type="text" name="name" id="name" placeholder="Name" onChange={this.handleChange} />
                  { this.state.nameValidation !== '' &&
                    <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.nameValidation }} />
                  }
                </div>
                <div className="form__row">
                  <input type="email" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} />
                  { this.state.emailValidation !== '' &&
                    <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.emailValidation }} />
                  }
                </div>
                <div className="form__row">
                  <input type="text" name="number" id="number" placeholder="Contact Number" onChange={this.handleChange} />
                </div>
                <div className="form__row">
                  <textarea name="enquiry" id="enquiry" placeholder="Enquiry" onChange={this.handleChange} />
                </div>
                <div className="form__row form__row--submit">
                  <input className="btn" type="submit" value="Contact" />
                </div>
              </form>
            }
            { this.state.result === 'success' &&
              <div className="contact-form__content contact-form__content--confirmation">
                <p dangerouslySetInnerHTML={{ __html: this.state.msg }} />
              </div>
            }
          </div>
        </div>
      </section>
    )

  }

}

export default AdvertiseForm
